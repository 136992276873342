import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { $ } from 'protractor';
import { PredialUrbanoService } from 'src/app/Sercivios/predial-urbano.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-consulta-adeudo',
  templateUrl: './consulta-adeudo.component.html',
  styleUrls: ['./consulta-adeudo.component.scss']
})
export class ConsultaAdeudoComponent implements OnInit {

  constructor(private router: Router) { }

  cve_catast = {
    mun: "",
    pob: "",
    cua: "",
    man: "",
    pre: "",
    uni: ""
  }

  es_movil = false;

  ngOnInit(): void {
    this.cve_catast.mun = environment.cve_municipio
    document.getElementById("pob").focus();

    if (window.innerHeight > window.innerWidth){
      this.es_movil = true
    } else {
      this.es_movil = false
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (event.target.value.length == 2) {
      switch (event.target.id) {
        case "pob":
          setTimeout(() => {
            console.log("evento", event)
            document.getElementById("cua").focus();
          }, 100);
          break
        case "cua":
          setTimeout(() => {
            document.getElementById("man").focus();
          }, 100);
          break
        case "man":
          setTimeout(() => {
            document.getElementById("pre").focus();
          }, 100);
          break
        case "pre":
          setTimeout(() => {
            document.getElementById("uni").focus();
          }, 100);
          break
      }
      //document.getElementById("")
    }
    return true;
  }

  ConsultarAdeudo(): void {
    this.router.navigate(["/Adeudo/" + this.cve_catast.mun + this.cve_catast.pob + this.cve_catast.cua + this.cve_catast.man + this.cve_catast.pre + this.cve_catast.uni])
  }

  MostrarModal() {
    console.log("Mostrar")
    //$('#myModal').modal('show')


  }
}
